<template>
    <div class="card mb-g">
        <div class="card-body">
            <div class="d-flex flex-row pb-3 pt-2  border-top-0 border-left-0 border-right-0 d-flex justify-content-center text-center">
                <h5 class="mb-0 flex-1 text-dark fw-500">
                    Mã QR cá nhân
                </h5>
            </div>
            <div class="pb-3 pt-2 border-top-0 border-left-0 border-right-0 text-muted d-flex justify-content-center">
                <el-row shadow="never" v-loading="loading" class="mr-100">
                    <qr-code :text="qrcode"></qr-code>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    import {USER_GET_QR_CODE} from "../../../core/services/store/user/users.module";
    export default {
        name: "EdutalkQrcode",
        data() {
            return {
                qrcode: '',
                loading: false
            }
        },
        methods: {
            fetchQRCode() {
                this.loading = true
                this.$store.dispatch(USER_GET_QR_CODE)
                    .then((response) => {
                        this.qrcode = response.data
                    })
                    .catch((error) => {
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
        mounted() {
            this.fetchQRCode();
        }
    };
</script>
